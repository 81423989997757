var articleContentTextTemplate = require('templates/articleContentText.hbs');
var articleContentTexttopOffersTitleTemplate = require('templates/articleContentText-topOffersTitle.hbs');

var articleContentTextLifeTimeTenure = require('templates/articleContentText-displayLifeTimeTenure.hbs');
var articleContentTextGenericTemplate = require('templates/articleContentText-generic.hbs');
var articleContentTextGroupTemplate = require('templates/articleContentText-group.hbs');
var articleContentTextAssociateTemplate = require('templates/articleContentText-associate.hbs');
var articleContentTextTermsAssociateTemplate = require('templates/articleContentText-nuaTermsAssociate.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'articleContentText',
  template: {
    'articleContentText': articleContentTextTemplate,
    'articleContentText-topOffersTitle': articleContentTexttopOffersTitleTemplate,
    'articleContentText-displayLifeTimeTenure': articleContentTextLifeTimeTenure,
    'articleContentText-generic': articleContentTextGenericTemplate,
    'articleContentText-group': articleContentTextGroupTemplate,
    'articleContentText-associate': articleContentTextAssociateTemplate,
    'articleContentText-nuaTermsAssociate': articleContentTextTermsAssociateTemplate
  }
});


